import React, { useEffect, useState } from 'react';
import Timer from '../components/Timer';

const NukeTimers = () => {
  const [timers, setTimers] = useState({ Alpha: null, Bravo: null, Charlie: null });
  const [codes, setCodes] = useState({ Alpha: '', Bravo: '', Charlie: '', date: '' });

  useEffect(() => {
    fetchNukeCodes();
    fetchTimers();
  }, []);

  const fetchNukeCodes = async () => {
    try {
        console.log('🔄 Fetching nuke codes...');

        const storedCodes = JSON.parse(localStorage.getItem('nukeCodes'));
        const now = new Date();
        console.log('🕒 Current Time:', now);

        if (storedCodes && storedCodes.date) {
            const storedExpiry = new Date(storedCodes.date);
            console.log('📅 Stored Expiry Date:', storedExpiry);

            if (storedExpiry > now) {
                console.log('✅ Stored nuke codes are still valid. Using them.');
                setCodes(storedCodes);
                return;
            }
        }

        console.log('🌐 Fetching new nuke codes from API...');
        const response = await fetch('https://api.fo76util.com/silocodes');
        const data = await response.json();

        console.log('📩 API Response:', data);

        // Convert `since_epoch` to Date
        const sinceEpochDate = new Date(data.since_epoch * 1000);
        
        // Ensure expiration is at the end of the 7th day (23:59:59 UTC)
        const validUntil = new Date(sinceEpochDate);
        validUntil.setUTCDate(validUntil.getUTCDate() + 7);
        validUntil.setUTCHours(23, 59, 59, 999); // Set time to end of day

        console.log('⏳ Since Epoch Date:', sinceEpochDate);
        console.log('📆 Corrected Valid Until Date:', validUntil);

        if (now > validUntil) {
            console.warn('❌ Nuke codes are expired. Fetching new ones is useless.');
            localStorage.removeItem('nukeCodes');
            return;
        }

        const newCodes = { 
            Alpha: data.ALPHA, 
            Bravo: data.BRAVO, 
            Charlie: data.CHARLIE, 
            date: validUntil.toISOString(),
            updated: sinceEpochDate.toISOString()
        };

        console.log('💾 Saving new nuke codes:', newCodes);
        setCodes(newCodes);
        localStorage.setItem('nukeCodes', JSON.stringify(newCodes));

    } catch (error) {
        console.error('🚨 Error fetching nuke codes:', error);
    }
};



  

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  

  const fetchTimers = () => {
    const storedTimers = JSON.parse(localStorage.getItem('nukeTimers')) || { Alpha: null, Bravo: null, Charlie: null };
    setTimers(storedTimers);
  };

  const saveTimers = (newTimers) => {
    setTimers(newTimers);
    localStorage.setItem('nukeTimers', JSON.stringify(newTimers));
  };

  const clearTimer = (label, confirm = false) => {
    if (confirm && !window.confirm(`Are you sure you want to clear the ${label} timer?`)) {
      return;
    }
    const newTimers = { ...timers, [label]: null };
    saveTimers(newTimers);
  };

  const clearAllTimers = () => {
    if (window.confirm("Are you sure you want to clear all timers?")) {
      const newTimers = { Alpha: null, Bravo: null, Charlie: null };
      saveTimers(newTimers);
    }
  };

  const addTimer = (label) => {
    const newTimer = new Date().toISOString();
    const newTimers = { ...timers, [label]: newTimer };
    saveTimers(newTimers);
  };

  const hasActiveTimer = (label) => timers[label] !== null;
  const allTimersAvailable = Object.values(timers).every(timer => timer === null);

  return (
    <div className="bg-gray-900 min-h-screen p-4 sm:p-6 md:p-8">
  <div className="mt-16 max-w-2xl mx-auto bg-gray-800 text-white rounded-lg shadow-lg p-4 sm:p-6 md:p-8">
    <h1 className="text-2xl font-bold mb-4">Personal Nuke Timers</h1>
    <p className="mb-6 text-gray-300 text-sm">
      Players have a personal 3-hour cooldown for launching a nuke from each silo, independent of the server's timer.
    </p>
    <div className="flex flex-wrap gap-4 mb-4">
      <button
        onClick={() => addTimer('Alpha')}
        className={`font-bold py-2 px-4 rounded ${hasActiveTimer('Alpha') ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700 text-white'}`}
        disabled={hasActiveTimer('Alpha')}>
        Add Alpha
      </button>
      <button
        onClick={() => addTimer('Bravo')}
        className={`font-bold py-2 px-4 rounded ${hasActiveTimer('Bravo') ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700 text-white'}`}
        disabled={hasActiveTimer('Bravo')}>
        Add Bravo
      </button>
      <button
        onClick={() => addTimer('Charlie')}
        className={`font-bold py-2 px-4 rounded ${hasActiveTimer('Charlie') ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700 text-white'}`}
        disabled={hasActiveTimer('Charlie')}>
        Add Charlie
      </button>
      <button
        onClick={clearAllTimers}
        className={`font-bold py-2 px-4 rounded ${allTimersAvailable ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500 hover:bg-red-700 text-white'}`}
        disabled={allTimersAvailable}
        style={allTimersAvailable ? { cursor: 'not-allowed' } : {}}>
        Clear All
      </button>
    </div>
    <div className="space-y-4">
      {Object.entries(timers).map(([label, launchTime]) => (
        <div key={label} className="relative flex items-center space-x-4 bg-gray-700 p-4 rounded shadow-md">
          {launchTime ? (
            <Timer label={label} launchTime={launchTime} onExpire={() => clearTimer(label)} />
          ) : (
            <div className="text-green-500">{label} is available to launch</div>
          )}
          {launchTime && (
            <button
              onClick={() => clearTimer(label, true)}
              className="absolute top-2 right-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full">
              X
            </button>
          )}
        </div>
      ))}
    </div>
    <p className="mt-6 text-center text-gray-400 text-sm italic">
      *Each silo on a server can launch a nuke once every 3 hours, regulated by a server-specific timer.
    </p>
    <div className="mt-4 text-gray-300">
      <h2 className="text-lg font-bold mb-2">Current Nuke Codes</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-gray-700 p-4 rounded shadow-md">
          <h3 className="text-xl font-bold">Alpha</h3>
          <p className="text-lg">{codes.Alpha}</p>
        </div>
        <div className="bg-gray-700 p-4 rounded shadow-md">
          <h3 className="text-xl font-bold">Bravo</h3>
          <p className="text-lg">{codes.Bravo}</p>
        </div>
        <div className="bg-gray-700 p-4 rounded shadow-md">
          <h3 className="text-xl font-bold">Charlie</h3>
          <p className="text-lg">{codes.Charlie}</p>
        </div>
      </div>
      <div className="text-sm text-gray-400 mt-4">Last Updated: {formatDate(codes.updated)}</div>
    </div>
  </div>
</div>

  );
};

export default NukeTimers;
